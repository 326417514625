var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "main" },
    [
      _c("el-header", { staticClass: "header" }, [
        _c("div", { staticClass: "logo" }, [
          _c("img", {
            attrs: {
              src: require("../assets/logo_head.png"),
              alt: "",
              srcset: ""
            }
          })
        ]),
        _c(
          "div",
          { staticClass: "user" },
          [
            _c(
              "el-popover",
              {
                ref: "popoverRef",
                attrs: { placement: "bottom", trigger: "click", width: "500" },
                model: {
                  value: _vm.taskVisible,
                  callback: function($$v) {
                    _vm.taskVisible = $$v
                  },
                  expression: "taskVisible"
                }
              },
              [
                [
                  _c(
                    "div",
                    { staticClass: "close-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          staticStyle: { "margin-right": "10px" },
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              _vm.allRead()
                            }
                          }
                        },
                        [_vm._v("全部已读")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              _vm.taskVisible = false
                            }
                          }
                        },
                        [_vm._v("关闭")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-tabs",
                    {
                      model: {
                        value: _vm.activeName,
                        callback: function($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName"
                      }
                    },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "任务提醒", name: "first" } },
                        [
                          _vm.taskValue == 0
                            ? _c("div", { staticClass: "no-message" }, [
                                _vm._v("暂无任务提醒")
                              ])
                            : _c("div", [
                                _vm.taskList[0]
                                  ? _c(
                                      "div",
                                      { staticClass: "task-reminder" },
                                      [
                                        _c("div", [
                                          _c(
                                            "div",
                                            { staticClass: "task-title" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.taskList[0].typeStr)
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "task-content" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.taskList[0].content)
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "task-time" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.taskList[0].createdDate
                                                )
                                              )
                                            ]
                                          )
                                        ]),
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "task-btn",
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                _vm.goDeal(_vm.taskList[0])
                                              }
                                            }
                                          },
                                          [_vm._v("前往处理")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.taskList[1]
                                  ? _c(
                                      "div",
                                      { staticClass: "task-reminder" },
                                      [
                                        _c("div", [
                                          _c(
                                            "div",
                                            { staticClass: "task-title" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.taskList[1].typeStr)
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "task-content" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.taskList[1].content)
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "task-time" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.taskList[1].createdDate
                                                )
                                              )
                                            ]
                                          )
                                        ]),
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "task-btn",
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                _vm.goDeal(_vm.taskList[1])
                                              }
                                            }
                                          },
                                          [_vm._v("前往处理")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.taskList[2]
                                  ? _c(
                                      "div",
                                      { staticClass: "task-reminder" },
                                      [
                                        _c("div", [
                                          _c(
                                            "div",
                                            { staticClass: "task-title" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.taskList[2].typeStr)
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "task-content" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.taskList[2].content)
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "task-time" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.taskList[2].createdDate
                                                )
                                              )
                                            ]
                                          )
                                        ]),
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "task-btn",
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                _vm.goDeal(_vm.taskList[2])
                                              }
                                            }
                                          },
                                          [_vm._v("前往处理")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]),
                          _c(
                            "div",
                            { staticClass: "all-task" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      _vm.handleClick(0)
                                    }
                                  }
                                },
                                [_vm._v("查看全部任务")]
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "系统消息", name: "second" } },
                        [
                          _c("div", { staticClass: "no-message" }, [
                            _vm._v("暂无系统消息")
                          ]),
                          _c(
                            "div",
                            { staticClass: "all-task" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      _vm.handleClick(1)
                                    }
                                  }
                                },
                                [_vm._v("查看全部消息")]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                _c(
                  "el-badge",
                  {
                    staticClass: "item-message",
                    attrs: {
                      slot: "reference",
                      value: _vm.taskValue,
                      max: 99,
                      hidden: _vm.badgeShow
                    },
                    nativeOn: {
                      click: function($event) {
                        _vm.taskShow()
                      }
                    },
                    slot: "reference"
                  },
                  [
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showNews,
                          expression: "showNews"
                        }
                      ],
                      staticClass: "news fa fa-bell-o"
                    })
                  ]
                )
              ],
              2
            ),
            _c(
              "el-dropdown",
              [
                _c("span", { staticClass: "el-dropdown-link name" }, [
                  _c("span", { staticClass: "welcome" }, [_vm._v("欢迎：")]),
                  _vm._v("\n          " + _vm._s(_vm.account) + "\n          "),
                  _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
                ]),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c(
                      "el-dropdown-item",
                      {
                        nativeOn: {
                          click: function($event) {
                            return _vm.onUserSet($event)
                          }
                        }
                      },
                      [_vm._v("账号设置")]
                    ),
                    _vm.loginType == 1
                      ? _c(
                          "el-dropdown-item",
                          {
                            nativeOn: {
                              click: function($event) {
                                return _vm.onAccountSecurity($event)
                              }
                            }
                          },
                          [_vm._v("账号安全")]
                        )
                      : _vm._e(),
                    _c(
                      "el-dropdown-item",
                      {
                        attrs: { divided: "" },
                        nativeOn: {
                          click: function($event) {
                            return _vm.outLogin($event)
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "fa fa-sign-out" }),
                        _vm._v("退出\n          ")
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "el-container",
        { staticClass: "main__body" },
        [
          _c(
            "el-aside",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showMenu,
                  expression: "showMenu"
                }
              ],
              staticClass: "menu",
              attrs: { width: "200px" }
            },
            [
              _c("i", {
                staticClass: "mobile-show close-menu fa fa-times",
                on: {
                  click: function($event) {
                    _vm.showMenu = false
                  }
                }
              }),
              _c(
                "el-menu",
                {
                  attrs: {
                    "default-active": _vm.$route.path,
                    "unique-opened": true,
                    router: ""
                  }
                },
                [
                  _vm.loginType == 1
                    ? _c(
                        "div",
                        _vm._l(_vm.menuList, function(item, index) {
                          return _c(
                            "el-submenu",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !item.notShow,
                                  expression: "!item.notShow"
                                }
                              ],
                              key: item.name,
                              attrs: { index: item.path }
                            },
                            [
                              _c("template", { slot: "title" }, [
                                _c("i", { class: [item.icon] }),
                                _c("span", [_vm._v(_vm._s(item.name))])
                              ]),
                              _vm._l(item.group, function(it, idx) {
                                return _c(
                                  "el-menu-item",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !it.notShow,
                                        expression: "!it.notShow"
                                      }
                                    ],
                                    key: it.name,
                                    attrs: { index: it.path },
                                    on: {
                                      click: function($event) {
                                        _vm.onMenu(
                                          it.path,
                                          index + 1 + "" + (idx + 1)
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(it.name))]
                                )
                              })
                            ],
                            2
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm.loginType == 2
                    ? _c(
                        "div",
                        [
                          _vm._l(_vm.merchantsMenu, function(it) {
                            return _c(
                              "el-menu-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !it.notShow,
                                    expression: "!it.notShow"
                                  }
                                ],
                                key: it.name,
                                attrs: { index: it.path }
                              },
                              [_vm._v(_vm._s(it.name) + "\n          ")]
                            )
                          }),
                          _vm._l(_vm.tramMenuList, function(item, index) {
                            return _c(
                              "el-submenu",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !item.notShow,
                                    expression: "!item.notShow"
                                  }
                                ],
                                key: item.name,
                                attrs: { index: item.path }
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c("i", { class: [item.icon] }),
                                  _c("span", [_vm._v(_vm._s(item.name))])
                                ]),
                                _vm._l(item.group, function(it, idx) {
                                  return _c(
                                    "el-menu-item",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !it.notShow,
                                          expression: "!it.notShow"
                                        }
                                      ],
                                      key: it.name,
                                      attrs: { index: it.path },
                                      on: {
                                        click: function($event) {
                                          _vm.onMenu(
                                            it.path,
                                            index + 1 + "" + (idx + 1)
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(it.name))]
                                  )
                                })
                              ],
                              2
                            )
                          })
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.loginType == 3 || _vm.loginType == 4
                    ? _c(
                        "div",
                        _vm._l(_vm.merchantsMenu, function(it) {
                          return _c(
                            "el-menu-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !it.notShow,
                                  expression: "!it.notShow"
                                }
                              ],
                              key: it.name,
                              attrs: { index: it.path }
                            },
                            [_vm._v(_vm._s(it.name) + "\n          ")]
                          )
                        }),
                        1
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _c(
            "el-container",
            { staticClass: "box" },
            [
              _c(
                "el-main",
                { staticClass: "app-el-main" },
                [
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.showMenu,
                        expression: "!showMenu"
                      }
                    ],
                    staticClass: "mobile-show show-menu fa fa-navicon",
                    on: {
                      click: function($event) {
                        _vm.showMenu = true
                      }
                    }
                  }),
                  _c(
                    "keep-alive",
                    [
                      !_vm.$route.meta.isNotKeep
                        ? _c("router-view", {
                            staticClass: "view",
                            attrs: { noticeFlag: _vm.noticeFlag }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.$route.meta.isNotKeep
                    ? _c("router-view", {
                        staticClass: "view",
                        attrs: { noticeFlag: _vm.noticeFlag }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._l(_vm.noticeList, function(item, index) {
                return [
                  _c(
                    "div",
                    { key: index, staticClass: "homePage-notice-dialog" },
                    [
                      _c("div", { staticClass: "notice-dialog-top" }, [
                        _c("span", { staticClass: "el-dialog__title" }, [
                          _vm._v("您有新的通知")
                        ]),
                        _c(
                          "button",
                          {
                            staticClass: "el-dialog__headerbtn",
                            attrs: { type: "button", "aria-label": "Close" },
                            on: {
                              click: function($event) {
                                _vm.noticeDialogClose(index)
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass:
                                "el-dialog__close el-icon el-icon-close"
                            })
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "notice-dialog-content" }, [
                        _c("p", { staticClass: "red" }, [_vm._v("提醒")]),
                        _c("p", [_vm._v(_vm._s(item.msg))]),
                        _c(
                          "p",
                          {
                            staticClass: "look-info",
                            on: {
                              click: function($event) {
                                _vm.noticeDetailFun(index)
                              }
                            }
                          },
                          [_vm._v("查看详情")]
                        )
                      ])
                    ]
                  )
                ]
              }),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "报警提醒",
                    width: "620px",
                    visible: _vm.dialogVisibleAlarm,
                    "close-on-click-modal": false,
                    "close-on-press-escape": false
                  },
                  on: {
                    close: function($event) {
                      _vm.closeAlarm()
                    },
                    "update:visible": function($event) {
                      _vm.dialogVisibleAlarm = $event
                    }
                  }
                },
                [
                  _c("div", { staticClass: "alarm-reminder-dialog" }, [
                    _c("img", {
                      attrs: { src: require("../assets/alarmIcon.png") }
                    }),
                    _c("div", { staticClass: "alarm-reminder-title" }, [
                      _vm._v(_vm._s(_vm.alarm.content))
                    ]),
                    _c("div", { staticClass: "alarm-reminder-remark" }, [
                      _c("div", { staticClass: "alarm-reminder-remark-row" }, [
                        _vm._v("设备MAC：" + _vm._s(_vm.alarm.mac))
                      ]),
                      _c("div", { staticClass: "alarm-reminder-remark-row" }, [
                        _vm._v("安装位置：" + _vm._s(_vm.alarm.location))
                      ]),
                      _c("div", { staticClass: "alarm-reminder-remark-row" }, [
                        _vm._v("报警时间：" + _vm._s(_vm.alarm.alarmTime))
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "alarm-reminder-closeBut" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                _vm.dialogVisibleAlarm = false
                              }
                            }
                          },
                          [_vm._v("关闭")]
                        )
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "audio",
                {
                  ref: "msgTipAudio",
                  attrs: { hidden: "", controls: "controls" }
                },
                [
                  _c("source", {
                    attrs: {
                      src: require("../assets/alarmtip.mp3"),
                      type: "audio/mpeg"
                    }
                  })
                ]
              ),
              _c(
                "el-footer",
                { staticClass: "footer", staticStyle: { height: "46px" } },
                [
                  _vm._v(
                    "深圳市威富视界 版权所有 IPC备案：粤ICP备17007206号-1 版本： " +
                      _vm._s(_vm.getVersion()) +
                      " \n      "
                  )
                ]
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }